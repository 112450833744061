import React from 'react';
import ReactPaginate from 'react-paginate';
const Paginate = (props) => {
    const {from, to, total_item, total_page, handlePageClick} = props.data
    return (
        <div className='w-100 d-flex justify-content-between' style={{ minWidth: '100vh' }}>
            <div>Show {from} to {to} of {total_item}</div>
            <ReactPaginate
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                pageCount={total_page || 1}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName='page-item'
                previousLinkClassName='page-link'
                nextClassName='page-item'
                nextLinkClassName='page-link'
                breakClassName='page-item'
                breakLinkClassName='page-link'
                activeClassName='active'
            />
        </div>
    );
}

export default Paginate;
